/* Gallery */
.gallery-container {
    margin-top: 80px;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    padding: 15px;
}

.gallery .pics {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.gallery .pics img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.gallery .pics:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.gallery .pics:hover img {
    transform: scale(1.1);
}

@media (max-width: 991px) {
    .gallery {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
    }
}

@media (max-width: 480px) {
    .gallery {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

/* Modal */
.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(255, 255, 255, 0.2);
    animation: fadeIn 0.4s ease;
}

.model.open svg {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.model.open svg:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000;
    transform: scale(1.1);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
