/* Apply global font styles */
html, body {
    background-color: #181a1b; /* Black background */
    color: #e4e4e4; /* White text for contrast */
    height: 100%; /* Full viewport height */
    width: 100%; /* Full viewport width */
    font-family: 'Segoe UI'; /* Use Segoe UI as the primary font */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

body, p, h1, h2, h3, h4, h5, h6, span, a, li {
    color: #e2e2e2 !important;

}

/* CSS Variables for Colors */
:root {
    --primary-color: #F4A906;
    --heading-color: #333;
    --btn-primary-bg: #F4A906;
    --btn-secondary-bg: #292929;
    --team-card-bg: #1c2a4a;
    --link-color: #fff;
    --link-active: #F4A906;
    --card-bg: linear-gradient(45deg, rgba(30, 39, 90, 1) 33%, rgba(40, 50, 100, 1) 84%);
    --counter-color: linear-gradient(90deg, #4b89c4 30%, rgba(50, 115, 180, 1) 55%, rgba(60, 120, 180, 1) 75%);
    --newsletter-bg: linear-gradient(90deg, rgba(30, 25, 60, 1) 20%, rgba(40, 35, 80, 1) 100%);
    --testimonial-bg: #23263b;
    --font-name: "Poppins", sans-serif;
    --small-text-color: #c8c8c8;
    --icon-bg: #0a123f;
  }
