@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/*Udaan Hero Poster*/
.udaan-hero {
background: url(../Images/Udaan/udaanbg2.png) no-repeat center center/cover;
padding: 120px 0 30px;
}

#jumbotron-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.logos-area-left,
.logos-area-right {
display: flex;
justify-content: center;
align-items: center;
width: 40%;
}

.logos-area-left img,
.logos-area-right img {
max-width: 100%;
height: auto;
object-fit: contain;
}

.jumbotron-text {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
gap: 10px;
}

.jumbotron-text h1 {
font-size: 3rem;
font-weight: bold;
color: white;
}

.jumbotron-text .highlight {
font-size: 1.25rem;
font-weight: 500;
color: white;
}

#udaan-logo {
max-width: 90%;
height: auto;
}

#social-wing-logo {
padding-top: 10px;
max-height: 250px;
height: auto;
}

.buttonc {
display: flex;
justify-content: center;
margin-top: 20px;
}

.buttonc button {
background-color: #F4A906;
color: white;
font-size: 1rem;
font-weight: bold;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.buttonc button:hover {
background-color: #c4401c;
}

/* Registered Participants */
.registered-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 30px;
    border-left: 2px solid rgba(255, 218, 139, 0.7);
  }
  
  .registered-count:hover {
    transform: scale(1.1);
  }
  
  .registered-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 228, 175, 0.1);
    border: 2px solid rgba(82, 56, 5, 0.3);
    transition: transform 0.3s ease, background 0.3s ease;
    padding: 10px 20px; 
    border-radius: 15px;
  }

  .registered-area h2 {
    margin-bottom: 0px;
    font-weight: bold;
  }
  
/*Countdown Styles*/
#countdown-div { /* Countdown Container */
height: auto;
padding: 40px 20px;
background: linear-gradient(135deg, #F4A906, #b07300);
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap: 40px;
color: white;
box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
text-align: center;
}

.countdown-text { /* Event Title Section */
font-family: "Inter", sans-serif;
text-align: center;
margin-bottom: 20px;
}

.event-title {
font-size: 2rem;
font-weight: bold;
margin-bottom: 5px;
}

#udaan_date {
font-size: 1.2rem;
font-weight: 500;
opacity: 0.8;
}

.countdown-timer { /* Timer Section */
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
}

.countdown-timer-box {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: rgba(255, 228, 175, 0.1);
border: 2px solid rgba(82, 56, 5, 0.3);
border-radius: 15px;
padding: 20px;
width: 100px;
height: 120px;
text-align: center;
transition: transform 0.3s ease, background 0.3s ease;
}

.countdown-timer-box:hover {
transform: scale(1.1);
background: rgba(255, 255, 255, 0.2);
}

.timer-value {
font-size: 2.5rem;
font-weight: bold;
margin: 0;
}

.timer-label {
font-size: 1rem;
font-weight: 500;
margin-top: 10px;
opacity: 0.9;
}

/*About Udaan Styles*/
.section {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: center;
gap: 3rem;
padding: 50px 5%;
}

.odd {
background-color: #2d2d2d;
}

/* Left Side Content */
.about-left-side {
flex: 1 1 55%;
}

.section-title {
font-family: "League Spartan", sans-serif;
font-size: 2.8rem;
font-weight: 700;
margin-bottom: 1rem;
color: #333;
}

.udaan-info {
font-family: "Poppins", sans-serif;
font-size: 1.2rem;
line-height: 1.6;
font-weight: 400;
color: #555;
display: block;
}

.deliverables-list {
margin-top: 1rem;
padding-left: 20px;
list-style-type: disc;
color: #555;
}

.deliverables-list > li {
margin-bottom: 0.5rem;
}

/* Right Side Image */
.about-right-side {
flex: 1 1 35%;
display: flex;
justify-content: center;
}

.about-right-side img {
width: 200px;
height: auto;
border-radius: 10px;
object-fit: cover;
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-right-side img:hover {
transform: scale(1.05);
box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/*About Right Side Girl*/
.about-right-side-girl {
    flex: 1 1 35%;
    display: flex;
    justify-content: center;
}
    
.about-right-side-girl img {
    width: 300px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-right-side-girl img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/*Body Style*/
*{
    font-family: "Poppins", sans-serif !important;
}
  
html{
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
    color: inherit;
}

a:visited{
    color: inherit;
}

/*Media Responsive*/
@media (max-width: 768px){
    .registered-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 15px;
        border-left: none;
        border-top: 2px solid rgba(255, 218, 139, 0.7);
    }

    #jumbotron-div {
        flex-direction: column; /* Stacks all items */
        padding-left: none;
    }

    .logos-area-left,
    .logos-area-right {
        flex: 1 1 100%; /* Take full width when stacked */
    }

    .jumbotron-text {
        padding: 9px;
    }

    .jumbotron-text h1 {
        font-size: 2rem;
    }

    .jumbotron-text .highlight {
        font-size: 2rem;
        padding: 5px;
    }

    #udaan-logo {
        max-width: 200px;
        margin-bottom: 20px;
    }

    #social-wing-logo {
        display: none;
    }

    #countdown-div {
        gap: 20px;
        padding: 20px;
    }

    .countdown-timer-box {
        width: 80px;
        height: 100px;
    }

    .timer-value {
        font-size: 2rem;
    }

    .timer-label {
        font-size: 0.9rem;
    }
    
    .section-title {
        font-size: 2.4rem;
    }

    .udaan-info {
        font-size: 1.1rem;
    }

    .deliverables-list {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    #udaan-logo {
        width: 200px;
        }

    .jumbotron-text h1 {
        font-size: 1.8rem;
    }

    .jumbotron-text .highlight {
        font-size: 1rem;
    }

    .buttonc button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
    
    .countdown-text .event-title {
        font-size: 1.5rem;
    }

    .countdown-text #udaan_date {
        font-size: 1rem;
    }

    .countdown-timer-box {
        width: 70px;
        height: 90px;
        padding: 15px;
    }

    .timer-value {
        font-size: 1.8rem;
    }

    .timer-label {
        font-size: 0.8rem;
    }
}