* {
    font-family: 'Poppins', sans-serif !important;
    padding: 0;
}

body {
    margin: 0;
}

/* Main Div */
#main-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.cardx {
    margin-top: 110px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

/* Image Styling */
.img > img {
    width: 80%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #FFA500;
}

.img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nss-jumbotron > p {
    font-size: 40px;
    font-weight: 600;
    color: #FFA500; /* Primary color for emphasis */
}

.nss-jumbotron > h1 {
    font-size: 100px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #ececec !important;
}

.button-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 100px;
    gap: 10px;
    margin-top: -20px;
}

/* Button Styling */
.button-area > #bt-1 > button {
    background-color: #FFA500;
    color: #ffffff;
    width: 210px;
    height: 50px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: #333333 0px 0px 10px;
}

.button-area > #bt-1 > button:hover {
    background-color: #e69500;
}

.button-area > #bt-2 > button {
    background-color: #4a4a4a;
    color: #ffffff;
    width: 210px;
    height: 50px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: #333333 0px 0px 10px;
}

.button-area > #bt-2 > button:hover {
    background-color: #333333;
}

/* Section Styling */
.cardx-2 {    
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.cardx-nss-1{
    padding: 10px;
    width: 95%;
    margin-right: 10px;
}

.cardx-nss-2{
    padding: 30px;
    border-radius: 50px;
    box-shadow: 15px 15px;
    background: linear-gradient(45deg, #bc7b00, #FFA500) !important;
}

#trans-button-1 {
    min-height: max-content;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 20px;
}

.sides {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    font-size: larger;
    width: 50%;
    flex-direction: column;
    color: #ffffff;
}

.sidesr {
    border-left: 3px solid;
}

.wrapper-sides1x {
    padding: 30px;
    text-align: center;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .img > img {
        width: 60%;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid #FFA500;
        margin-bottom: 10px;
    }

    .cardx{
        margin-bottom: 50px;
    }
    #main-div {
        flex-direction: column;
    }
    .nss-jumbotron {
        text-align: center;
    }
    .nss-jumbotron > h1 {
        font-size: 3rem;
    }
    .nss-jumbotron > p {
        font-size: 1.2rem;
    }
    .sides {
        padding: 0;
        width: 400px;
    }
    .sidesr {
        border-left: none;
    }

    #trans-button-1 {
        flex-direction: column;
    }

    .wrapper-sides1x {
        margin-top: 30px;
        padding: 40px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .wrapper-bottom{        
        border-top: 2px solid;
    }

    #trans-button-1 {
        min-height: max-content;
        display: flex; /* Use flexbox */
        flex-direction: column; /* Maintain side-by-side alignment */
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        width: 100%; /* Full width */
        border-radius: 10px;
        padding: 20px;
        gap: 20px; /* Space between sides */
    }
    
}

@media screen and (max-width: 570px) {
    .sides {
        padding: 0;
        width: 250px;
    }

    .button-area {
        margin-right: -70px;
    }

    .button-area > #bt-1 > button {
        width: 150px;
    }

    .button-area > #bt-2 > button {
        width: 150px;
    }
}