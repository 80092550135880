.container-map{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.container-map1{
    margin: 40px;
    margin-top: 110px;
}

.map-text{
    color: black;
    text-align: center;
    font-size: 40px;
}