/* Base Styles */
.team {
  margin-top: 80px;
}

body {
  background: var(--body-bg);
  font-family: var(--font-name);
}

a {
  text-decoration: none;
  color: var(--link-color);
}

/* h2 {
  color: var(--heading-color);
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 30px;
  text-align: center;
} */

section {
  padding: 20px;
}

.container {
  width: 90%;
  max-width: 1200px;
  padding: 0 15px;
}

.description {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--small-text-color);
  max-width: 500px;
  margin: auto;
}

/* Button Styles */
button {
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: var(--btn-primary-bg);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: var(--btn-secondary-bg);
  transform: scale(1.05);
}

/* Teams Section */
.our__team {
  margin: 40px 0;
}

.team__content {
  text-align: center;
  margin-bottom: 20px;
}

.team__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.team__item {
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.team__item:hover {
  transform: scale(1.05);
}

.team__img img {
  width: 100%;
  border-radius: 5%;
  max-width: 250px;
}

.team__details h4 {
  font-size: 18px;
  margin: 10px 0;
}

.description {
  font-size: 14px;
  color: #555;
}

.team__member-social span {
  margin: 5px;
  cursor: pointer;
}

.team__member-social i {
  font-size: 18px;
  transition: color 0.3s ease;
}

.team__member-social i:hover {
  color: #0073b1;
}

/* Responsive Design */
@media only screen and (max-width: 1024px) {
  .team__item {
    width: 30%;
  }

  .team__wrapper {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  .team__wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .team__item {
    width: 45%;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 576px) {
  .team__item {
    width: 100%;
  }
}

/* Category Section Styling */
.team__section {
  margin-top: 40px;
}

.team__section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Custom Styles for the Three Sections */
.faculty-section {
  background-color: #f1f5fb;
}

.core-section {
  background-color: #e6eff9;
}

.joint-core-section {
  background-color: #e9f5fe;
}
