.bg-image1{
    background-image: url(../Images/Udaan/bgudaan.png);
}

.bg-image2{
    background-image: url(../Images/Udaan/mapbg.JPG);
}

@media screen and (max-width: 768px){
    .know-more{
        margin-top: 20px;
    }
    
}